export const PROJECT_TYPES = {
  UNITY: 'unity',
  SHAPEDIVER: 'shapediver',
  NO_TYPE: ''
}

export const SensorState = {
  1: 'ONLINE',
  2: 'OFFLINE',
  3: 'ALARM' // Means we have hit a sensor limit
}

export const SENSOR_PROJECTS: string[] = [
  'Van Brienenoord East',
  'Zijkanaal C',
  'Van Brienenoord West',
  'Galecopper',
  'Galecopper Bypass'
  //   'ZW3 streng 9 M36'
  //   'ZO2 streng 4 M36',
  //   'ZO2 streng 10 M36',
  //   'ZW3 streng 3 M36',
  //   'ZW3 streng 3 M42',
  //   'ZW3 streng 9 M42',
  //   'ZO2 streng 4 M42',
  //   'ZO2 streng 10 M42',
  //   'SW3-3 strain gauges',
  //   'SW3-9 strain gauges',
  //   'ZO2-4 strain gauges',
  //   'ZO2-10 strain gauges'
]

export const SENSOR_SUPPLIERS: string[] = ['osmos', 'mistras', 'hollandia', 'EMHA']

export const SENSOR_TAGS: string[] = [
  'HW1',
  'HW2',
  'HW3',
  'HO1',
  'HO2',
  'HO3',
  'WB1b',
  'WB1o',
  'WB1int',
  'WB1ext',
  'OB1b',
  'OB1o',
  'OB1int',
  'OB1ext',
  'OB2b',
  'OB2o',
  'OB2int',
  'OB2ext',
  'WB2b',
  'WB2o',
  'WB2int',
  'WB2ext',
  'WB3b',
  'WB3o',
  'OB3b',
  'OB3o',
  'WB4b',
  'WB4o',
  'OB4b',
  'OB4o',
  'WT1_N',
  'WT1_S',
  'WT2_N',
  'WT2_S',
  'OT1_N',
  'OT1_S',
  'OT2_N',
  'OT2_S',
  'X1_NM',
  'X1_NB',
  'X1_OM',
  'X1_OB',
  'X1_ZM',
  'X1_ZB',
  'X1_WM',
  'X1_WB',
  'H1a',
  'H2',
  'H3',
  'H4a',
  'H4b',
  'TD1',
  'TD2',
  'TD3',
  'TD4a',
  'TD4b',
  'TD5',
  'TD6',
  'TD7',
  'K1n',
  'K2n',
  'K3n',
  'K4n',
  'K5n',
  'K6n',
  'K7n',
  'K8n',
  'K9n',
  'K1z',
  'K2z',
  'K3z',
  'K4z',
  'K5z',
  'K6z',
  'K7z',
  'K8z',
  'K9z',
  'R1',
  'R2',
  'R3',
  'R4',
  'R5',
  'R6',
  'R7',
  'R8',
  'S1',
  'S2',
  'S3',
  'S4',
  'NH1',
  'NH2',
  'NH3',
  'NH4',
  'NH5',
  'NH6',
  'NH7',
  'NH8',
  'NB',
  'NDD1m',
  'NDD1s',
  'NDD1ns',
  'NDD3m',
  'NDD4m',
  'NDD4s',
  'NDD6m',
  'NDD6s',
  'NDD8m',
  'NBT1x',
  'NBT1Y',
  'NBT1z',
  'NBT2x',
  'NBT2Y',
  'NBT2z',
  'ZH1',
  'ZH2',
  'ZH3',
  'ZH4',
  'ZH5',
  'ZH6',
  'ZH7',
  'ZH8',
  'ZB',
  'ZDD1m',
  'ZDD1s',
  'ZDD1ns',
  'ZDD3m',
  'ZDD4m',
  'ZDD4s',
  'ZDD6m',
  'ZDD6s',
  'ZDD8m',
  'ZBT1x',
  'ZBT1Y',
  'ZBT1z',
  'ZBT2x',
  'ZBT2Y',
  'ZBT2z',
  'WBo',
  'WBb',
  'WBext',
  'WBint',
  'Thw',
  'Tho',
  'OBo',
  'Obb',
  'Obext',
  'Obint',
  'HW',
  'DD12W',
  'HO',
  'DD12O',
  'A-Z0-A-SG',
  'A-Z1-A-SG',
  'A-Z2-A-SG',
  'A-Z3-A-SG',
  'A-Z4-A-SG',
  'A-Z5-A-SG',
  'A-Z6-A-SG',
  'A-Z7-A-SG',
  'D-Z3-1-A-SG',
  'D-Z3-2-A-SG',
  'D-Z3-3-A-SG',
  'D-Z3-4-A-SG',
  'D-Z3-5-A-SG',
  'D-Z3-6-A-SG',
  'D-Z3-7-A-SG',
  'D-Z3-8-A-SG',
  'B-Z0-B-SG',
  'B-Z1-B-SG',
  'B-Z2-B-SG',
  'B-Z3-B-SG',
  'B-Z4-B-SG',
  'B-Z5-B-SG',
  'B-Z6-B-SG',
  'B-Z7-B-SG',
  'C-Z3-1-B-SG',
  'C-Z3-2-B-SG',
  'C-Z3-3-B-SG',
  'C-Z3-4-B-SG',
  'C-Z3-5-B-SG',
  'C-Z3-6-B-SG',
  'C-Z3-7-B-SG',
  'C-Z3-8-B-SG',
  'A-Z0-A-SG_Temperature',
  'A-Z1-A-SG_Temperature',
  'A-Z2-A-SG_Temperature',
  'A-Z3-A-SG_Temperature',
  'A-Z4-A-SG_Temperature',
  'A-Z5-A-SG_Temperature',
  'A-Z6-A-SG_Temperature',
  'A-Z7-A-SG_Temperature',
  'D-Z3-1-A-SG_Temperature',
  'D-Z3-2-A-SG_Temperature',
  'D-Z3-3-A-SG_Temperature',
  'D-Z3-4-A-SG_Temperature',
  'D-Z3-5-A-SG_Temperature',
  'D-Z3-6-A-SG_Temperature',
  'D-Z3-7-A-SG_Temperature',
  'D-Z3-8-A-SG_Temperature',
  'B-Z0-B-SG_Temperature',
  'B-Z1-B-SG_Temperature',
  'B-Z2-B-SG_Temperature',
  'B-Z3-B-SG_Temperature',
  'B-Z4-B-SG_Temperature',
  'B-Z5-B-SG_Temperature',
  'B-Z6-B-SG_Temperature',
  'B-Z7-B-SG_Temperature',
  'C-Z3-1-B-SG_Temperature',
  'C-Z3-2-B-SG_Temperature',
  'C-Z3-3-B-SG_Temperature',
  'C-Z3-4-B-SG_Temperature',
  'C-Z3-5-B-SG_Temperature',
  'C-Z3-6-B-SG_Temperature',
  'C-Z3-7-B-SG_Temperature',
  'C-Z3-8-B-SG_Temperature',
  '362059',
  '362015',
  '362061',
  '362062',
  '362063',
  '362064',
  '362065',
  '362087',
  '362067',
  '362068',
  '362069',
  '362070',
  '362071',
  '362072',
  '362073',
  '362074',
  '362016',
  '362076',
  '362077',
  '362078',
  '362079',
  '362080',
  '3620103',
  '3620104',
  '3620105',
  '3620106',
  '3620107',
  '3620108',
  '362019',
  '3620110',
  '3620111',
  '3620112',
  '3620113',
  '3620114',
  '3620115',
  '3620116',
  '3620117',
  '3620118',
  '3620119',
  '3620120',
  '3620121',
  '3620122',
  '362089',
  '3620124',
  '3620125',
  '3620126',
  '3620127',
  '3620128',
  '3620129',
  '3620130',
  '3620131',
  '3620132',
  '3620133',
  '3620134',
  '3620135',
  '3620136',
  '3620137',
  '3620138',
  '3620139',
  '3620140',
  '3620141',
  '3620142',
  '3620143',
  '362090',
  '362091',
  '3620146',
  '362037',
  '362038',
  '362039',
  '362040',
  '362041',
  '362042',
  '362018',
  '362044',
  '362045',
  '362046',
  '362047',
  '362048',
  '362049',
  '362050',
  '362051',
  '362052',
  '362053',
  '362054',
  '362055',
  '362081',
  '362057',
  '362058',
  '422009',
  '422010',
  '422011',
  '422012',
  '422015',
  '422016',
  '422017',
  '422018',
  'CS800533Channel01',
  'CS800533Channel02',
  'CS800533Channel05',
  'CS800533Channel06',
  'CS800813Channel101',
  'CS800813Channel103',
  'CS800533Channel03',
  'CS800533Channel04',
  'CS800533Channel07',
  'CS800533Channel08',
  'CS800813Channel102',
  'CS800813Channel104',
  'CS890761Channel09',
  'CS890761Channel10',
  'CS890761Channel13',
  'CS890761Channel14',
  'CS876694Channel109',
  'CS876694Channel111',
  'CS890761Channel11',
  'CS890761Channel12',
  'CS890761Channel15',
  'CS890761Channel16',
  'CS876694Channel110',
  'CS876694Channel112'
]
