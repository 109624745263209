import React, { useState, useEffect } from 'react'
import { Typography, TextField } from '@mui/material'
import { Project } from '../../../shared/interfaces'
import { createProjects } from '../../../shared/helper'
import { ProjectGrid } from '../../../common/components/grid/ProjectGrid'
import { getProjects } from '../../../shared/api'
import styled from 'styled-components'
import SearchIcon from '@mui/icons-material/Search'
import LoadingAnimation from '../Loading/Loading'
import { useTranslation } from 'react-i18next'
import '@arc-web/components/dist/components/spinner/arc-spinner.js'

const Projects: React.FC<any> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [apps, setApps] = useState<Project[]>([])
  const { i18n } = useTranslation()

  useEffect(() => {
    getProjects()
      .then((result) => setApps(createProjects(result)))
      .catch((error) => setIsError(true))
      .finally(() => setIsLoading(false))
  }, [])

  console.log(apps)

  return (
    <arc-container
      // TODO : fix it correctly
      theme="light"
      // style={{ position: 'fixed' }}
    >
      <>
        <ProjectContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography
              variant="h4"
              color="error"
              style={{
                marginRight: '10px',
                display: 'inline-block',
                alignItems: 'center',
                fontWeight: 300
              }}
            >
              Bridge Portal
            </Typography>
            <Typography variant="caption" style={{ alignItems: 'center', flex: 1 }}>
              {apps.length ? ` - ${apps.length} projects` : ``}
            </Typography>
            <div style={{ display: 'flex' }}>
              <TextField
                id="standard-basic"
                variant="standard"
                size="small"
                style={{ marginRight: '2.5rem', width: '300px' }}
                error
                InputProps={{
                  startAdornment: <SearchIcon></SearchIcon>
                }}
              />
            </div>
          </div>
          <Typography variant="subtitle1">Bridging all project information together</Typography>
          <div>
            <ProjectsGrid>
              {apps.length > 0 ? (
                <div
                  style={{
                    width: '100%',
                    marginLeft: '-20px',
                    marginTop: '20px',
                    minHeight: '200px'
                  }}
                >
                  <ProjectGrid
                    addProject={false}
                    items={apps}
                    showDots={false}
                    rows={3}
                    cardType="projectCard"
                  ></ProjectGrid>
                </div>
              ) : (
                <LoadingAnimation text={i18n.t('Loading')} spinnerSize={56} />
              )}
            </ProjectsGrid>
          </div>
        </ProjectContainer>
      </>
    </arc-container>
  )
}

export default Projects

const ProjectContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '3rem',
  paddingBot: '1rem',
  paddingLeft: '3rem',
  paddingRight: '3rem',
  height: '100%',
  overflowY: 'scroll',
  overflowX: 'hidden'
})

const ProjectsGrid = styled('div')({
  overflowY: 'hidden',
  height: '100%'
  // '& .styled-scrollbars::-webkit-scrollbar-track': {
  //   webkitBoxShadow: 'inset 0 0 1px rgba(75, 75, 75, 0.3)',
  //   wdith: '6px',
  //   height: '6px',
  //   backgroundColor: '#f5f5f5'
  // },
  // '& .styled-scrollbars::-webkit-scrollbar': {
  //   webkitBoxShadow: 'inset 0 0 1px rgba(75, 75, 75, 0.3)',
  //   borderRadius: '10px',
  //   backgroundColor: '#f5f5f5'
  // },
  // '& .styled-scrollbars::-webkit-scrollbar-thumb': {
  //   borderRadius: '10px',
  //   backgroundColor: '#707070'
  // }
})
