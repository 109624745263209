import React, { useEffect } from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import TextField from '@material-ui/core/TextField'

import { ParamData } from '../Menu'

interface IProps {
  param: ParamData
  updateModel: any
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '18px',
        fontWeight: 'bold'
      },
      display: 'flex'
    }
  })

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const MenuTextField: React.FC<PropsType> = (props) => {
  const { classes, param, updateModel } = props

  let typingTimer: any = null
  const updateTextfield = (event, param) => {
    clearTimeout(typingTimer)
    typingTimer = setTimeout(() => {
      if (event.target.value) {
        updateModel(param, event.target.value)
      }
    }, 1000)
  }
  useEffect(() => {
    return () => {
      clearTimeout(typingTimer)
    }
  }, [typingTimer])

  return (
    <form noValidate autoComplete="off">
      <TextField
        label={param.name}
        defaultValue={param.defval}
        onChange={(event) => updateTextfield(event, param)}
        className={classes.root}
        size={'small'}
        InputProps={{ style: { fontSize: 14 } }}
      />
    </form>
  )
}

export default withStyles(styles)(MenuTextField)
