import React from 'react'
import Carousel from 'react-grid-carousel'
import { ProjectCard } from '../card/ProjectCard'
import { ProjectHighlightCard } from '../card/ProjectHighlightCard'

interface IProps {
  // projectId: string;
  items: any
  addProject: boolean
  showDots: boolean
  rows?: number
  cardType: 'projectCard' | 'highlightCard'
}

const CardTypes = {
  projectCard: ProjectCard,
  highlightCard: ProjectHighlightCard
}

export const ProjectGrid: React.FC<IProps> = (props: IProps) => {
  return (
    <Carousel
      responsiveLayout={carouselLayout(props.rows ? props.rows : 1)}
      showDots={props.showDots}
      hideArrow={false}
    >
      {props.items.map((val, i) => {
        const CardType = CardTypes[props.cardType]
        return (
          <Carousel.Item key={i}>
            <CardType
              key={val.id}
              title={val.name}
              upLoadedBy="Albin Ingmarsson" // To be updated
              version="0.1" // To be updated
              id={val.id}
              projectImage={
                val.image
                  ? val.image
                  : `${process.env.PUBLIC_URL}/assets/sampleImages/sampleImage${Math.floor(
                      (Math.random() + 0.5) * 3
                    )}.jpg`
              }
              location={val.location}
              description={val.description}
              type={val.type}
              stream_id={val.stream_id}
            ></CardType>
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}

const carouselLayout = (rows: number) => {
  return [
    {
      breakpoint: 800,
      cols: 1,
      rows: rows,
      gap: 10
    },
    {
      breakpoint: 1100,
      cols: 2,
      rows: rows,
      gap: 10
    },
    {
      breakpoint: 1400,
      cols: 3,
      rows: rows,
      gap: 20
    },
    {
      breakpoint: 1600,
      cols: 4,
      rows: rows,
      gap: 20
    },
    {
      breakpoint: 1800,
      cols: 5,
      rows: rows,
      gap: 20
    },
    {
      breakpoint: 2000,
      cols: 6,
      rows: rows,
      gap: 20
    },
    {
      breakpoint: 3500,
      cols: 8,
      rows: rows,
      gap: 20
    }
  ]
}
