import { EnvironmentConfig } from '../shared/interfaces'

export default {
  project: {
    name: 'prod',
    api: {
      url: 'https://api.bridges.arup.com/'
    }
  },
  sensor: {
    name: 'prod',
    api: {
      url: 'https://k3p7spgxrc.execute-api.eu-west-1.amazonaws.com/Prod/',
      key: 'Jk1jC7LGQt3uOqRLlp11U63usuV7g3HDafgpBARA'
    }
  },
  activeDirectory: {
    auth: {
      clientId: '4b9a8b32-4903-443e-b288-934cd2f6f458', // Bridge Portal
      authority: 'https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea',
      redirectUri: 'https://bridges.arup.com/'
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  }
} as EnvironmentConfig
