import config from '../config'
import axios from 'axios'

function getConf() {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
      'Content-Type': 'application/json'
    }
  }
}
function getSensorConf() {
  return {
    headers: {
      'x-api-key': config.sensor.api.key,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }
}
export async function getProjects() {
  try {
    const response = await axios.get(config.project.api.url + 'projects', getConf())
    return response.data
  } catch (e) {
    console.error(e)
    return e
  }
}

export async function getSensors(project?: string, supplier?: string, tag?: string) {
  try {
    const conf: any = getSensorConf()
    conf.params = {}
    if (project) conf.params['project'] = project
    if (supplier) conf.params['supplier'] = supplier
    if (tag) conf.params['tag'] = tag
    const response = await axios.get(config.sensor.api.url + 'sensors', conf)
    return response.data.result
  } catch (e) {
    console.error(e)
    return e
  }
}

export async function getSensorData(
  sensorId: number,
  startTime: string,
  endTime: string,
  timeBucket: string
) {
  try {
    const conf: any = getSensorConf()
    conf.params = {
      sensor_id: sensorId,
      start_time: startTime,
      end_time: endTime,
      time_bucket: timeBucket
    }
    const response = await axios.get(config.sensor.api.url + 'sensors/data', conf)
    return response.data.result
  } catch (e) {
    console.error(e)
    return 'Failed retrieving sensor information'
  }
}
