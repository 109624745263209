import { Project, Sensor } from '../shared/interfaces'

import NoImageAvailable from '../images/no_image_available.png'
import ZijkanaalImage from '../images/Zijkanaal.png'
import GalecopperImage from '../images/GalecopperBridge.jpg'
import BrienenoordImage from '../images/Brienenoord.jpg'
import WaelschenheuvelImage from '../images/Viaduct_Waelschenheuvel.png'
import { SensorState } from './constants'

export function createProjects(result: any[]): Project[] {
  const projects: Project[] = []
  result?.forEach((project) => {
    projects.push(createProject(project))
  })
  return projects
}

function createProject(project: any): Project {
  const { id, name, active, job_number, description, stream_id, location, viewtype, sensor } = project
  return {
    id: id,
    name: name,
    status: active ? 'online' : 'offline',
    job_number: job_number,
    image: getProjectImage(name),
    stream_id: changeStreamID(name, stream_id),
    location: location,
    type: createType(viewtype),
    sensor: sensor,
    description: createAppDescription(name, description),
    project_id: createProjectid(name)
  }
}
// ID for the project used for routing. Lower case, without spaces and without special characters.
function createProjectid(projectID: string): string {
  const name = projectID.toLocaleLowerCase().replace(/ /g, '_')
  projectID.replace(/[^a-zA-Z ]/g, '')
  return name
}

// Temporary solution
function changeStreamID(projectName: string, stream_id: string): string {
  const name = projectName.toLocaleLowerCase()
  if (name.includes('brienenoord west')) {
    return 'b043f7cdeefeede06f937997a143d4030fe9ee382a17bc34c37261e8fc93bf7e1579b8fef4fbf064f84d6d0bf72f76432be517ee44d10634a6dd9eb488a86b3ec59dd77c13bbc452dd84320cd4fa1f763a61ad3c5b763ef50b588428ff620df7f32421564fd534-4c41c62c28925f3d3fccb14efceda9fb'
  } else if (name.includes('waelschenheuvel')) {
    return 'https://monitoring.bridges.arup.com/d/oEwN2j04k/viaduct-waelschenheuvel-demo?orgId=1'
  }
  return stream_id
}

// Temporary solution
function createAppDescription(name: string, description: string): string {
  if (name.toLocaleLowerCase().includes('bypass')) {
    return 'Bridge Monitoring'
  } else if (name.toLocaleLowerCase().includes('galecopper')) {
    return 'Analysis Results'
  } else if (name.toLocaleLowerCase().includes('brienenoord')) {
    return 'Analysis Results'
  }
  return description
}

// Temporary solution
function createType(type: string): string {
  if (type === null) {
    return 'link'
  }
  return type
}

// TODO This is a temporary solution until the backend provides the image
function getProjectImage(projectName: string): string {
  const name = projectName.toLocaleLowerCase()
  if (name.includes('brienenoord')) {
    return BrienenoordImage
  }
  if (name.includes('galecopper')) {
    return GalecopperImage
  }
  if (name.includes('zijkanaal')) {
    return ZijkanaalImage
  }
  if (name.includes('waelschenheuvel')) {
    return WaelschenheuvelImage
  }
  return NoImageAvailable
}

// Sensor result structure:
// 0 - Sensor ID
// 1 - Sensor tag name
// 2 - Sensor location description
// 3 - Sensor supplier name
// 4 - Sensor group name
// 5 - Sensor x-coordinate
// 6 - Sensor y-coordinate
// 7 - Sensor z-coordinate
// 8 - Sensor state (1 is online,  2 is offline, 3 is alarm (has hit the sensor limit))
export function createSensors(result: any[]): Sensor[] {
  const sensors: Sensor[] = []
  result?.forEach((sensor) => {
    sensors.push(createSensor(sensor))
  })
  return sensors
}

function createSensor(sensor: any): Sensor {
  return {
    id: sensor[0],
    name: sensor[1],
    location: sensor[2],
    supplier: sensor[3],
    group: sensor[4],
    x: sensor[5],
    y: sensor[6],
    z: sensor[7],
    state: SensorState[sensor[8]]
  }
}
