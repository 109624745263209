import React from 'react'
import { Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import './ProjectCard.css'

interface IProps {
  title: string
  id: string
  projectImage?: string | undefined
  location?: string | undefined
  description?: string | undefined
}

export const ProjectHighlightCard: React.FC<IProps> = (props: IProps) => {
  const navigator = useNavigate()

  const onClickNewProject = (e) => {
    navigator('/project/' + props.id)
    window.location.reload()
  }

  return (
    <div className="projectCard" style={{ minHeight: '200px' }}>
      <img
        onClick={onClickNewProject}
        className="projectCardImage"
        height="200px"
        width="100%"
        src={props.projectImage}
        alt={props.title}
      ></img>

      <Typography
        variant="subtitle1"
        style={{
          // fontWeight: "500",
          lineHeight: '0.5',
          marginTop: '5%',
          color: 'rgba(var(--arc-white-000), 0.9)'
        }}
      >
        {props.title}
      </Typography>
      <Typography variant="caption" style={{ color: 'rgba(var(--arc-white-000), 0.9)' }}>
        {props.location}
      </Typography>
    </div>
  )
}
