import * as React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { ParamData } from '../Menu'

interface IProps {
  param: ParamData
  updateModel: any
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    inputName: {
      fontSize: '14px',
      fontWeight: 'bold'
    },
    inputSel: {
      fontSize: '14px'
    }
  })

type ClassKey = 'inputName' | 'inputSel'
type PropsType = IProps & WithStyles<ClassKey>

const MenuFormControl: React.FC<PropsType> = (props) => {
  const { classes, param, updateModel } = props

  const updateDropdown = (event, param) => {
    const newValue = event.target.value
    updateModel(param, newValue)
    param.value = newValue
  }

  return (
    <form>
      <InputLabel className={classes.inputName}>{param.name}</InputLabel>
      <FormControl fullWidth={true}>
        <Select
          value={param.value}
          onChange={(event) => updateDropdown(event, param)}
          className={classes.inputSel}
        >
          {param?.choices?.map((choice, index) => (
            <MenuItem value={index} key={index}>
              {choice}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </form>
  )
}

export default withStyles(styles)(MenuFormControl)
