import React, { useState, useEffect } from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import UnityInterface from '../Unity/Unity'
import Shapediver from '../Shapediver/Shapediver'
import { Project } from '../../../shared/interfaces'
import { PROJECT_TYPES } from '../../../shared/constants'
import { useLocation } from 'react-router-dom'
import { getProjects } from '../../../shared/api'
import { createProjects } from '../../../shared/helper'

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      padding: '0'
    },
    location: {
      color: theme.palette.primary.main,
      letterSpacing: '0px',
      fontWeight: 'bolder',
      paddingBottom: '4px'
    },
    name: {
      color: theme.palette.secondary.main,
      letterSpacing: '0px',
      paddingBottom: '10px'
    },
    cardDiv: {
      margin: '30px'
    }
  })

type ClassKey = 'root' | 'location' | 'name' | 'content' | 'cardDiv'
type PropsType = WithStyles<ClassKey>

const Apps: React.FC<PropsType> = (props: PropsType) => {
  const [isLoading, setIsLoading] = useState(true)
  // const [isError, setIsError] = useState(false)
  const selectedProjectID = useLocation().pathname.split('/').pop()
  const [apps, setApps] = useState<Project[]>([])
  const [selectedApp, setSelectedApp] = useState<any>()

  // TODO improve this for further components
  const [loadUnity, setLoadUnity] = useState(false)
  const [loadShapediver, setLoadShapediver] = useState(false)

  useEffect(() => {
    getProjects().then((result) => setApps(createProjects(result)))
  }, [])

  useEffect(() => {
    console.log(apps)
    const filteredApp = apps.filter((apps) => apps.id.toString() === selectedProjectID)[0]
    setSelectedApp(filteredApp)
    switch (filteredApp?.type) {
      case PROJECT_TYPES.UNITY:
        setLoadUnity(true)
        break
      case PROJECT_TYPES.SHAPEDIVER:
        setLoadShapediver(true)
        break
      default:
        console.log('no component to load')
      // setIsError(true)
    }
    setIsLoading(false)
  }, [apps, selectedProjectID])
  console.log(selectedApp)

  return (
    <>
      {!isLoading && loadUnity && <UnityInterface project={selectedApp} />}
      {!isLoading && loadShapediver && <Shapediver project={selectedApp} />}
    </>
  )
}

export default withStyles(styles)(Apps)
