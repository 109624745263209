import React, { useState, useEffect } from 'react'
import { withStyles, WithStyles, createStyles, Theme, Grid } from '@material-ui/core'

// import Loading from '../../../Loading/Loading'
import MenuSlider from './MenuItems/MenuSlider'
import MenuFormControl from './MenuItems/MenuFormControl'
import MenuTextField from './MenuItems/MenuTextField'
import MenuFile from './MenuItems/MenuFileInput'
import { showMenusVisSelected, showMenusLegendSelected } from './MenuItemsShown'

interface Parameters {
  Element?: []
  data?: ParamData[]
}

export interface ParamData {
  name?: string
  id?: string
  type?: string
  hidden?: boolean
  visualization?: string
  choices?: string[]
  defval?: number
  min?: number
  max?: number
  decimalplaces?: number
  value?: number
  data?: string[]
  updateName?: string
}

interface IProps {
  api: any
  onItemClick: () => void
  setIsLoadingMenu: any
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '17vw',
      maxHeight: '80vh',
      overflow: 'auto'
    },
    menu: {
      marginTop: '2px',
      marginLeft: '10px',
      marginRight: '10px',
      fontSize: '12px'
    },
    gridItem: {
      padding: theme.spacing(0.25)
    }
  })

type ClassKey = 'root' | 'menu' | 'gridItem'
type PropsType = IProps & WithStyles<ClassKey>

const MenuPage: React.FC<PropsType> = (props) => {
  const { classes } = props

  const [parameters, setParameters] = useState<Parameters>({})
  const [horFilter, setHorFilter] = useState<ParamData>({})
  const [vertFilter, setVertFilter] = useState<ParamData>({})
  const [allParameters, setAllParameters] = useState<Parameters>({})
  const [menuItemsShown, setMenuItemsShown] = React.useState({})

  // const [isLoading, setIsLoading] = useState(true)

  // Update Shapediver model
  const updateModel = (param, value) => {
    props.onItemClick()
    if (param.id === 'Manual') {
      props.api.parameters.updateAsync({
        name: param.updateName,
        value: String(value)
      })
    } else {
      props.api.parameters.updateAsync({
        id: param.id,
        value: value
      })
    }
  }

  // Create menu component
  const createComponent = (param: ParamData, index: number, menuItemsShown) => {
    // Adding legend menus based on selection
    if (param.name === 'Legend Type') {
      menuItemsShown = showMenusLegendSelected(param, menuItemsShown)
    }
    // Hide/show menu items
    allParameters?.data?.forEach((param) => {
      param.hidden = true
      menuItemsShown.forEach((param_true) => {
        switch (param_true) {
          case param.name:
            param.hidden = false
        }
      })
    })

    // Creating menuitems
    if (!param || param.hidden) {
      return <div />
    }
    switch (param.type) {
      case 'StringList':
        return <MenuFormControl param={param} updateModel={updateModel} />
      case 'Int':
      case 'Float':
        return <MenuSlider param={param} updateModel={updateModel} />
      case 'String':
        return <MenuTextField param={param} updateModel={updateModel} />
      case 'File':
        return <MenuFile param={param} updateModel={updateModel} api={props.api} />
      default:
        return <div />
    }
  }

  // Fetching menu data from Shapediver
  useEffect(() => {
    if (!props.api) {
      return
    }
    props.api.scene.addEventListener(props.api.scene.EVENTTYPE.VISIBILITY_ON, () => {
      const parameters = props.api.parameters.get()
      setParameters(parameters)
    })
  })

  // Fetching text output data from Shapediver
  useEffect(() => {
    if (!props.api) {
      return
    }
    props.api.scene.addEventListener(props.api.scene.EVENTTYPE.SUBSCENE_PUBLISHED, () => {
      const horFilter = props.api.scene.getData({ name: 'horizontalFilter' }).data[0].data
      const vertFilter = props.api.scene.getData({ name: 'verticalFilter' }).data[0].data
      const visSelected = props.api.scene.getData({ name: 'visualization' }).data[0].data
      setHorFilter(horFilter)
      setVertFilter(vertFilter)
      setMenuItemsShown(showMenusVisSelected(visSelected))
    })
  }, [props.api])

  // Adding menuitems generated manually to list of menuitems from Shapediver
  useEffect(() => {
    if (parameters.data! !== undefined && Object.keys(vertFilter).length) {
      if (Object.keys(allParameters).length) {
        allParameters.data![4] = horFilter
        allParameters.data![5] = vertFilter
        setAllParameters(allParameters)
      } else {
        parameters.data!.splice(4, 0, horFilter)
        parameters.data!.splice(5, 0, vertFilter)
        setAllParameters(parameters)
      }
      // setIsLoading(false)
      props.setIsLoadingMenu(false)
    }
  }, [allParameters, parameters, horFilter, vertFilter, props])

  const GridItemSize = (param) => {
    let xs: any
    if (param.name === 'Nodes' || param.name === 'Elements') {
      xs = 6
    } else {
      xs = 12
    }
    return xs
  }

  return (
    <div className={classes.root}>
      <div className={classes.menu}>
        <Grid container>
          {/* {isLoading && (
            <Loading spinnerSize={96} text="Loading menu..." height="42vh" width="15vw"></Loading>
          )}
          {!isLoading && ( */}
          <>
            {allParameters?.data?.map((param, index) => (
              <Grid item xs={GridItemSize(param)} key={index} className={classes.gridItem}>
                {createComponent(param, index, menuItemsShown)}
              </Grid>
            ))}
          </>
          {/* )} */}
        </Grid>
      </div>
    </div>
  )
}

export default withStyles(styles)(MenuPage)
