import React, { useEffect } from 'react'
import { acquireTokenSilentAndSetLocal } from '../../auth'

export function AcquireToken(props: any) {
  useEffect(() => {
    acquireTokenSilentAndSetLocal()
      .then((response) => props.tokenRetrieved())
      .catch((error) => {
        console.log(error)
        props.tokenRetrievedError()
      })
    // eslint-disable-next-line
  }, [])

  // return an empty div because we don't need anything displayed
  return <div></div>
}
