import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

interface IProps {
  text: string
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 'var(--arc-spacing-medium)'
    },
    text: {
      fontSize: 'var(--arc-font-size-xx-large)',
      letterSpacing: 'var(--arc-letter-spacing-normal)',
      lineHeight: 'var(--arc-line-height-normal)',
      fontWeight: 'bolder'
    }
  })

type ClassKey = 'root' | 'text'

type PropsType = IProps & WithStyles<ClassKey>

const Error: React.FC<PropsType> = (props: PropsType) => {
  return (
    <>
      {/* TODO Improve the design */}
      <div className={props.classes.root}>
        <div className={props.classes.text}>{props.text}</div>
      </div>
    </>
  )
}

export default withStyles(styles)(Error)
