import React from 'react'
import { UnauthenticatedTemplate } from '@azure/msal-react'
import SignIn from './SignIn'

export function Unauthenticated() {
  return (
    <UnauthenticatedTemplate>
      <SignIn />
    </UnauthenticatedTemplate>
  )
}
