import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Unity, { UnityContext } from 'react-unity-webgl'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

import Loading from '../Loading/Loading'
import Error from '../Error/Error'
import { getSensorData, getSensors } from '../../../shared/api'
import config from '../../../config'
import { Project } from '../../../shared/interfaces'

const unityContext = new UnityContext({
  loaderUrl: 'unity/brimon.loader.js',
  dataUrl: 'unity/brimon.data',
  frameworkUrl: 'unity/brimon.framework.js',
  codeUrl: 'unity/brimon.wasm'
})

interface IProps {
  project: Project | undefined
}

const styles = () =>
  createStyles<ClassKey, {}>({
    root: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center'
    }
  })

function parseSensorDataUnityKey(key: string): string[] {
  const sensorId = key.split('&')[0].replace('/sensors/data?sensor_id=', '')
  const startTime = key.split('&')[1].replace('start_time=', '')
  const endTime = key.split('&')[2].replace('end_time=', '')
  const timeBucket = key.split('&')[3].replace('time_bucket=', '')
  return [sensorId, startTime, endTime, timeBucket]
}

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const UnityInterface: React.FC<PropsType> = (props: PropsType) => {
  const isProd = config.project.name === 'prod'
  const { project } = props
  const { i18n } = useTranslation()
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [progression, setProgression] = useState(0)

  useEffect(() => {
    if (!project) {
      // no project found, ignore the rest of the code
      return
    }

    console.log(`Stream ID to load is: ${project.stream_id}`)
    unityContext.send('---Context---', 'LoadBridge', JSON.stringify(project.name))
    unityContext.on('loaded', () => setIsLoaded(true))
    unityContext.on('progress', (progression) => setProgression(Math.round(progression * 100)))
    unityContext.on('error', (message) => {
      console.error('Error loading Unity: ', message)
      setIsError(false) // TODO disabled for now because the Unity app loads with errors...
      setErrorMessage(message)
    })
    if (!isProd) {
      unityContext.on('debug', (message) => console.log('Unity debug: ', message))
    }

    unityContext.on('OnNeedSensors', () => {
      getSensors(project.name)
        .then((sensors) => {
          const response = { key: '/sensors', result: sensors }
          if (!isProd) {
            console.log('Sending:' + JSON.stringify(response))
          }
          unityContext.send('---Context---', 'AddSensorDataToCache', JSON.stringify(response))
        })
        .catch((error) => {
          console.error(error)
          setIsError(true)
        })
    })
    unityContext.on('OnNeedSensorData', (key: string) => {
      const [sensorId, startTime, endTime, timeBucket] = parseSensorDataUnityKey(key)
      getSensorData(+sensorId, startTime, endTime, timeBucket)
        .then((sensorData) => {
          const response = { key: key, result: sensorData }
          unityContext.send('---Context---', 'AddSensorDataToCache', JSON.stringify(response))
        })
        .catch((error) => {
          console.error(error)
          setIsError(true)
        })
    })
    unityContext.on('ShowStrainGaugeChart', () => {})
    unityContext.on('OnPostComment', () => {})

    // eslint-disable-next-line
  }, [project])

  return (
    <>
      {!isLoaded && !isError && (
        <Loading text={i18n.t('loadingBridge') + ' ' + progression + '%'} spinnerSize={96} />
      )}
      {isError && <Error text={errorMessage} />}
      <div className={props.classes.root}>
        <Unity
          unityContext={unityContext}
          style={{
            visibility: isLoaded && !isError ? 'visible' : 'hidden',
            height: isLoaded && !isError ? '100%' : '0vh',
            width: isLoaded && !isError ? '100%' : '0vh',
            background: 'grey'
          }}
        />
      </div>
    </>
  )
}

export default withStyles(styles)(UnityInterface)
