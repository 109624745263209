import * as React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Slider from '@material-ui/core/Slider'
import { ParamData } from '../Menu'

interface IProps {
  param: ParamData
  updateModel: any
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      padding: '0'
    },
    inputName: {
      fontSize: '14px',
      fontWeight: 'bold'
    }
  })

type ClassKey = 'root' | 'inputName'
type PropsType = IProps & WithStyles<ClassKey>

const MenuSlider: React.FC<PropsType> = (props) => {
  const { classes, param, updateModel } = props

  const updateSlider = (event, newValue, param) => {
    const newValSlider = parseInt(newValue)
    updateModel(param, newValSlider)
    param.value = newValSlider
  }

  return (
    <form>
      <InputLabel className={classes.inputName}>{param.name}</InputLabel>
      <Slider
        className={classes.root}
        defaultValue={param.defval}
        valueLabelDisplay="auto"
        step={param?.type === 'Int' ? 1 : 1 / Math.pow(10, param?.decimalplaces!)}
        marks
        min={param.min}
        max={param.max}
        onChangeCommitted={(event, newValue) => {
          updateSlider(event, newValue, param)
        }}
      />
    </form>
  )
}

export default withStyles(styles)(MenuSlider)
