import React, { useState, useEffect } from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

// import Loading from '../../../Loading/Loading'

interface LegendData {
  header?: string
  color?: LegendColor[]
  values?: string[]
}

interface LegendColor {
  type: string
  data: any[]
}

interface IProps {
  api: any
  setIsLoadingLegend: any
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      // alignSelf: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '10vw',
      position: 'absolute',
      zIndex: 1,
      paddingTop: '20px',
      backgroundColor: 'white'
    },
    text: {
      fontSize: 16,
      fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center'
    },
    containerValues: {
      fontSize: 16,
      width: 80,
      height: 30,
      textAlign: 'left'
    },
    containerColor: {
      width: 20,
      height: 30
    },
    containerColorHalf: {
      width: 20,
      height: 15
    }
  })

type ClassKey = 'root' | 'text' | 'containerValues' | 'containerColor' | 'containerColorHalf'
type PropsType = IProps & WithStyles<ClassKey>

const Legend: React.FC<PropsType> = (props) => {
  const { classes } = props
  const [legendData, setLegendData] = useState<LegendData>({})
  // const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!props.api) {
      return
    }

    props.api.scene.addEventListener(props.api.scene.EVENTTYPE.SUBSCENE_PUBLISHED, () => {
      let legendData = props.api.scene.getData({ name: 'legendData' })
      legendData = legendData.data[0].data
      setLegendData(legendData)
      // setIsLoading(false)
      props.setIsLoadingLegend(false)
    })
  }, [props])

  return (
    <div className={classes.root}>
      {/* {isLoading && <Loading spinnerSize={48} text="Loading legend..." height="42vh" width="10vw"></Loading>}
      {!isLoading && (
        <> */}
      <span className={classes.text}>{legendData['header']}</span>
      <div>
        <Grid container direction="row" spacing={0}>
          <Grid item xs={3}>
            <div className={classes.containerColorHalf} />
            {legendData?.color?.map((color, key) => (
              <div
                key={key}
                className={classes.containerColor}
                style={{ backgroundColor: `rgb(${color.data})` }}
              />
            ))}
            <div className={classes.containerColorHalf} />
          </Grid>
          <Grid item xs={9}>
            {legendData?.values?.map((value, key) => (
              <div key={key} className={classes.containerValues}>
                {value}
              </div>
            ))}
          </Grid>
        </Grid>
      </div>
      {/* </>
      )} */}
    </div>
  )
}

export default withStyles(styles)(Legend)
