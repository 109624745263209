import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const locales = ['nl-NL', 'en-US']
export const supportedLanguages = locales

const localeResources = {
  'en-US': {
    common: require('./common/locales/en-US/common.json')
  },
  'nl-NL': {
    common: require('./common/locales/nl-NL/common.json')
  }
}

const frozenLocales = Object.freeze(locales)
export function localesImmutable() {
  return frozenLocales
}

const fallbackLanguages = {
  nl: ['nl-NL'],
  default: ['en-US']
}

const i18n = i18next
i18n.use(LanguageDetector)
i18n.init({
  resources: localeResources,
  fallbackLng: fallbackLanguages,
  ns: 'common',
  defaultNS: 'common',
  react: { wait: true },
  debug: false,
  cache: { enabled: true }
})

export default i18n
