import React, { useState, createContext } from 'react'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@material-ui/core'

export const ReactThemeContext = createContext({
  darkMode: false,
  toggleDarkMode: (e: any) => {}
})

interface IProps {}

export const BridgeThemeProvider: React.FC<IProps> = ({ children }) => {
  const [darkMode, setDarkMode] = useState<boolean>(false)
  const toggleDarkMode = (e: CustomEvent) => {
    const { preferences } = e.detail
    const { theme } = preferences
    setDarkMode(() => theme === 'dark')
  }

  const theme = createTheme({
    typography: {
      fontFamily: "'Roboto', Arial, Helvetica, sans-serif",
      fontSize: 11,
      caption: {
        fontSize: 10,
        fontWeight: 400
      },
      h1: {
        fontSize: 64,
        fontWeight: 400
      },
      h2: {
        fontSize: 44,
        fontWeight: 400
      },
      h3: {
        fontSize: 32,
        fontWeight: 100
      },
      h4: {
        fontSize: 24,
        fontWeight: 400
      },
      h5: {
        fontSize: 30,
        fontWeight: 500
      },
      h6: {
        fontSize: 14
      },
      body1: {
        fontSize: 14
      },
      button: {
        fontSize: 14,
        textTransform: 'capitalize'
      },
      subtitle1: {
        fontSize: 12
      }
    },
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: 'rgb(var(--arc-color-primary))'
      },
      secondary: {
        main: 'rgb(var(--arc-color-secondary))'
      },
      error: {
        main: 'rgb(var(--arc-color-error))'
      },
      warning: {
        main: 'rgb(var(--arc-color-warning))'
      },
      info: {
        main: 'rgb(var(--arc-color-info))'
      },
      success: {
        main: 'rgb(var(--arc-color-success))'
      },
      background: {
        default: 'rgb(var(--arc-background-color))',
        paper: 'rgb(var(--arc-container-color))'
      }
    }
  })
  return (
    <ReactThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ReactThemeContext.Provider>
  )
}
