import React, { useState, useEffect } from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

// import Loading from '../../../Loading/Loading'

interface ApiTableData {
  Aheader?: string[]
  Bpos0?: string[]
  Bpos1?: string[]
  data?: string[]
}

interface IProps {
  api: any
  setIsLoadingTable: any
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 12
  },
  body: {
    textAlign: 'center',
    fontSize: 12
  }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow)

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: 'flex',
      width: '65vw',
      position: 'absolute',
      zIndex: 1,
      alignSelf: 'flex-end',
      backgroundColor: 'white'
    },
    container: {
      borderRadius: '0px'
    }
  })

type ClassKey = 'root' | 'container'
type PropsType = IProps & WithStyles<ClassKey>

const Datatable: React.FC<PropsType> = (props) => {
  const { classes } = props
  const [tableData, setTableData] = useState<ApiTableData>({})
  // const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!props.api) {
      return
    }

    props.api.scene.addEventListener(
      props.api.scene.EVENTTYPE.SELECT_ON,
      () => {
        // setIsLoading(true)
        props.setIsLoadingTable(true)
      },
      [props]
    )

    props.api.scene.addEventListener(props.api.scene.EVENTTYPE.SUBSCENE_PUBLISHED, () => {
      const tableData: ApiTableData = props.api.scene.getData({ name: 'tableData' }).data[0].data
      setTableData(tableData)
      // setIsLoading(false)
      props.setIsLoadingTable(false)
    })
  }, [props])

  return (
    <div className={classes.root}>
      {/* {isLoading && (
        <Loading spinnerSize={48} text="Loading result table..." height="19vh" width="60vw"></Loading>
      )}
      {!isLoading && ( */}
      <TableContainer className={classes.container} component={Paper}>
        {tableData && (
          <Table size="small">
            <TableHead>
              <TableRow>
                {tableData?.Aheader?.map((cellText, key) => (
                  <StyledTableCell key={key} component="th">
                    {cellText}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(tableData).map(([rowKey, rowCells]) => {
                if (rowKey === 'Aheader') {
                  return null
                }
                return (
                  <StyledTableRow key={rowKey}>
                    {rowCells.map((cellText, key) => (
                      <StyledTableCell key={key}>{cellText}</StyledTableCell>
                    ))}
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {/* )} */}
    </div>
  )
}

export default withStyles(styles)(Datatable)
