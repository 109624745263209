import React, { useEffect, useState } from 'react'

import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import MenuPage from './Menu/Menu'
import Legend from './Legend/Legend'
import Datatable from './Table/Datatable'
import Controls from './Controls/Controls'
import Loading from './Controls/Loading'
import { registerClickFunctions } from './api/ClickFunctions'

interface IProps {
  ticket: string
  modelViewUrl: string
  setIsLoadingBridge: any
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    top: {
      display: 'flex',
      flexDirection: 'row'
    },
    menu: {
      display: 'flex',
      flexGrow: 1,
      width: '15vw',
      alignItems: 'top',
      justifyContent: 'center',
      backgroundColor: 'white',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      marginRight: '10px',
      paddingTop: '10px'
    },
    viewerContainer: {
      display: 'flex'
    },
    viewerRight: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    viewerLeft: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    viewer: {
      display: 'flex',
      flexDirection: 'row',
      width: '65vw',
      height: '80vh',
      background: 'white',
      zIndex: 1
    },
    loading: {
      display: 'flex',
      width: '30vw',
      position: 'absolute',
      zIndex: 1,
      paddingTop: '20px'
    },
    placeholder: {
      display: 'flex',
      flexGrow: 1,
      width: '15vw'
    }
  })

type ClassKey =
  | 'root'
  | 'top'
  | 'menu'
  | 'viewerContainer'
  | 'viewerRight'
  | 'viewerLeft'
  | 'viewer'
  | 'loading'
  | 'placeholder'
type PropsType = IProps & WithStyles<ClassKey>

const Viewer: React.FC<PropsType> = (props) => {
  const { classes } = props

  const [api, setApi] = useState<any>()
  // const [isLoadingBridge, setIsLoadingBridge] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingModel, setIsLoadingModel] = useState(true)
  const [isLoadingMenu, setIsLoadingMenu] = useState(true)
  const [isLoadingLegend, setIsLoadingLegend] = useState(true)
  const [isLoadingTable, setIsLoadingTable] = useState(true)

  const containerSD = React.createRef<HTMLDivElement>()

  useEffect(() => {
    const _container = containerSD.current

    const settings: any = {
      ticket: props.ticket,
      modelViewUrl: props.modelViewUrl,
      container: _container
    }

    const api = new (window as any).SDVApp.ParametricViewer(settings)
    setApi(api)

    const assetTitles: string[] = ['elements0', 'elements1', 'elements2', 'elements3'] // Name of GH components for clickable geometry
    registerClickFunctions(api, assetTitles)
  }, [])

  useEffect(() => {
    if (!api) {
      return
    }
    api!.scene?.addEventListener(api.scene.EVENTTYPE.SUBSCENE_PUBLISHED, () => {
      if (api.parameters.get().data! !== undefined) {
        setIsLoadingModel(false)
      }
    })
  }, [api])

  const onItemClick = () => {
    setIsLoading(true)
    setIsLoadingModel(true)
    setIsLoadingMenu(true)
    setIsLoadingLegend(true)
    setIsLoadingTable(true)
  }

  useEffect(() => {
    if (!isLoadingModel && !isLoadingMenu && !isLoadingLegend && !isLoadingTable) {
      setIsLoading(false)
      props.setIsLoadingBridge(false)
      setIsLoadingLegend(false)
      setIsLoadingTable(false)
    } else {
      setIsLoading(true)
    }
  }, [isLoadingModel, isLoadingMenu, isLoadingLegend, isLoadingTable])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.top}>
          <div className={classes.menu}>
            <MenuPage api={api} onItemClick={onItemClick} setIsLoadingMenu={setIsLoadingMenu} />
          </div>
          <div className={classes.viewerContainer}>
            <div className={classes.viewerRight}>
              <div className={classes.viewer} ref={containerSD}></div>
              <Legend api={api} setIsLoadingLegend={setIsLoadingLegend} />
              <Loading isLoading={isLoading} />
              <Datatable api={api} setIsLoadingTable={setIsLoadingTable} />
            </div>
            <Controls api={api} triggerLoading={isLoadingModel} />
          </div>
          <div className={classes.placeholder}></div>
        </div>
      </div>
    </>
  )
}

export default withStyles(styles)(Viewer)
