import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { ParamData } from '../Menu'

interface IProps {
  param: ParamData
  updateModel: any
  api: any
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '18px',
        fontWeight: 'bold'
      },
      display: 'flex',
      marginTop: '10px'
      // marginLeft: '10px'
      // marginRight: '25px'
    }
  })

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const MenuFile: React.FC<PropsType> = (props) => {
  const { param, updateModel } = props
  const fileInput = React.useRef<any>()

  const updateFile = (param, fileInput) => {
    const file = fileInput.current.files[0]
    updateModel(param, file)
  }

  return (
    <form>
      <div>
        <Button
          onClick={() => fileInput.current.click()}
          variant="outlined"
          style={{ textTransform: 'none', width: '100%' }}
        >
          {param.name}
        </Button>

        <input
          ref={fileInput}
          type="file"
          style={{ display: 'none' }}
          onChange={() => {
            updateFile(param, fileInput)
          }}
        />
      </div>
    </form>
  )
}

export default withStyles(styles)(MenuFile)
