import React, { useState } from 'react'
import { AuthenticatedTemplate } from '@azure/msal-react'
import { AcquireToken } from './AcquireToken'

export function Authenticated(props: any) {
  const [hasToken, setHasToken] = useState<boolean>(false)
  const [tokenError, setTokenError] = useState<boolean>(false)

  const tokenRetrieved = () => {
    setHasToken(true)
  }
  const tokenRetrievedError = () => {
    setTokenError(true)
  }

  return (
    <AuthenticatedTemplate>
      <AcquireToken tokenRetrieved={tokenRetrieved} tokenRetrievedError={tokenRetrievedError} />
      {hasToken && props.children}
      {/* Design a decent error page here */}
      {tokenError && <div>Error retrieving Token</div>}
    </AuthenticatedTemplate>
  )
}
