export function registerClickFunctions(api: any, assetTitles: string[]) {
  clickAble(api, assetTitles)
  elementClick(api, assetTitles)
}

// defining effects for hoverable and selectable objects
const hoverEffect = {
  active: {
    name: 'colorHighlight',
    options: {
      color: [100, 100, 100]
    }
  }
}
const selectionEffect = {
  active: {
    name: 'colorHighlight',
    options: {
      color: [255, 255, 255]
    }
  }
}

// defining a group of hoverable and selectable objects
const ElementGroup = {
  id: 'elements',
  hoverable: true,
  hoverEffect: hoverEffect,
  selectable: true,
  selectionEffect: selectionEffect
}

function clickAble(api: any, assetTitles: string[]) {
  api.scene.addEventListener(api.scene.EVENTTYPE.VISIBILITY_ON, () => {
    const updateObjects: any = []
    api.scene.updateInteractionGroups(ElementGroup)

    for (let i = 0; i < assetTitles.length; i++) {
      const assets = api.scene.get({ name: assetTitles[i] }, 'CommPlugin_1')

      for (let assetNum in assets.data) {
        const asset = assets.data[assetNum]
        const updateObject = {
          id: asset.id,
          duration: 0,
          interactionGroup: ElementGroup.id
        }
        updateObjects.push(updateObject)
      }
    }
    api.scene.updatePersistentAsync(updateObjects, 'CommPlugin_1')
  })
}

function elementClick(api: any, assetTitles: string[]) {
  const selectCallback = (event) => {
    // find which element was selected
    let scenePaths: any = []
    let noElBeforeGroup: any = 0

    for (let i = 0; i < assetTitles.length; i++) {
      const asset_temp = api.scene.get({ name: assetTitles[i] }, 'CommPlugin_1').data
      var asset: any = []
      if (asset_temp[0].format === 'glb') {
        asset = asset_temp[0]
      } else {
        asset = asset_temp[1]
      }

      let scenePath = {
        assetName: asset.name,
        scenePath: asset.scenePath.split('.')[1],
        noElBeforeGroup: noElBeforeGroup
      }
      scenePaths.push(scenePath)
      var elPrevGroup = asset.content.length
      noElBeforeGroup = noElBeforeGroup + elPrevGroup
    }

    let ElementID = event.scenePath.split('.')[1]
    let ElementRef = event.scenePath.split('.')[2].split('_')[1]

    for (let i = 0; i < scenePaths.length; i++) {
      if (scenePaths[i].scenePath === ElementID) {
        ElementRef = (parseInt(ElementRef, 10) + scenePaths[i].noElBeforeGroup).toString()
      }
    }
    api.parameters.updateAsync({
      name: 'selectedElement',
      value: ElementRef
    })
  }
  api.scene.addEventListener(api.scene.EVENTTYPE.SELECT_ON, selectCallback)
}
