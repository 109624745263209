import * as Msal from '@azure/msal-browser'
import config from '../config'
import { UserInfo } from '../shared/interfaces'

const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read', 'email']
}
let avatar = ''

const initMsal = () => {
  const msal = new Msal.PublicClientApplication(config.activeDirectory)
  return msal
}

const getAvatarRes = async () => {
  return fetch('https://graph.microsoft.com/v1.0/me/photos/48x48/$value', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'image/jpg'
    }
  })
}

const handleTokenResponse = async (res: Msal.AuthenticationResult) => {
  localStorage.setItem('accessToken', res.accessToken)
  localStorage.setItem('idToken', res.idToken)
  avatar = URL.createObjectURL(await (await getAvatarRes()).blob())
}

export const msal = initMsal()

export const getAccount = () => msal.getAllAccounts()[0]

export const getUserInfo = (): UserInfo => {
  return {
    name: getAccount().name || '',
    email: getAccount().username,
    avatar: avatar
  }
}

export const acquireTokenSilentAndSetLocal = async () => {
  const request: any = {
    ...loginRequest,
    account: getAccount()
  }

  try {
    const res = await msal.acquireTokenSilent(request)
    return handleTokenResponse(res)
  } catch (e) {
    msal.acquireTokenPopup(request).then((res_2) => handleTokenResponse(res_2))
  }
}

export const signOut = () => {
  const logoutRequest = {
    account: getAccount(),
    postLogoutRedirectUri: config.activeDirectory.auth.redirectUri
  }
  msal.logoutRedirect(logoutRequest).then(() => {
    console.log('logout is done now')
  })
}

export const isAuthenticated = () => {
  const accessToken = localStorage.getItem('accessToken')
  const idToken = localStorage.getItem('idToken')

  if (accessToken && idToken) return true
  return false
}
