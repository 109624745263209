import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import LoadingGraphic from '../../../Loading/Loading'

interface IProps {
  isLoading: any
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: 'flex',
      width: '30vw',
      position: 'absolute',
      zIndex: 1,
      paddingTop: '20px'
    }
  })

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const Loading: React.FC<PropsType> = (props) => {
  const { classes } = props

  return (
    <div className={classes.root}>
      {props.isLoading && <LoadingGraphic spinnerSize={40} text={''} height="5vh"></LoadingGraphic>}
    </div>
  )
}

export default withStyles(styles)(Loading)
