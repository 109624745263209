import React from 'react'
import { Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import './ProjectCard.css'

interface IProps {
  title: string
  id: string
  upLoadedBy?: string | undefined
  version?: string | undefined
  projectImage?: string | undefined
  location?: string | undefined
  description?: string | undefined
  type?: string | undefined
  stream_id?: string | undefined
}

export const ProjectCard: React.FC<IProps> = (props: IProps) => {
  const navigator = useNavigate()

  const hasDisabledStyle = (project): React.CSSProperties => {
    const title = project.title.toLocaleLowerCase()
    const isProjectDisabled =
      !title.includes('galecopper') && !title.includes('brienenoord') && !title.includes('waelschenheuvel')
    return {
      opacity: isProjectDisabled ? '0.50' : '1',
      pointerEvents: isProjectDisabled ? 'none' : 'auto'
    }
  }

  const onClickNewProject = (e) => {
    if (props.type === 'link') {
      window.open(props.stream_id)
    } else {
      navigator('/project/' + props.id)
      // window.location.reload()
    }
  }

  return (
    <div style={hasDisabledStyle(props)}>
      <div className="projectCard" style={{ minHeight: '200px' }}>
        <img
          onClick={onClickNewProject}
          className="projectCardImage"
          height="200px"
          width="100%"
          src={props.projectImage}
          alt={props.title}
        ></img>
        <div
          className="CardBar"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '0px'
          }}
        >
          <div className="MenuLeft" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption" style={{ marginLeft: '1.5px', fontSize: '0.55rem' }}>
              {props.location && props.location}
            </Typography>
          </div>
          <div className="MenuRight" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption" style={{ marginRight: '1.5px', fontSize: '0.55rem' }}>
              {props.version && `Version: ${props.version}`}
            </Typography>
          </div>
        </div>
        <Typography
          variant="body1"
          color="error"
          component="div"
          style={{ fontWeight: 600, fontSize: '0.95rem' }}
        >
          {props.title}
        </Typography>
        {/* <Typography variant="subtitle1">{props.location}</Typography> */}
        <Typography variant="subtitle1">{props.description}</Typography>
      </div>
    </div>
  )
}
