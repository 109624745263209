import React from 'react'
// import { Route, Switch } from 'react-router'
// import { ReactThemeContext } from '../../../reactTheme'
// import type { ArcContainer } from '@arc-web/components'
import '@arc-web/components/dist/components/container/arc-container.js'
import AppBar from './AppBar'
import { Route, Routes } from 'react-router-dom'
import Projects from '../Projects/Projects'
import Apps from '../Apps/Apps'

const Frame: React.FC<any> = () => {
  // const { toggleDarkMode } = useContext(ReactThemeContext)

  // /* Add an eventListener to the arc-container component */
  // const containerRef = useRef<ArcContainer>(null)
  // useEffect(() => {
  //   /* Wrap within an if statement to ensure that the element is actually available */
  //   if (containerRef && containerRef.current) {
  //     containerRef.current.addEventListener('arc-accessibility-change', toggleDarkMode)
  //     return () => containerRef.current?.removeEventListener('arc-accessibility-change', toggleDarkMode)
  //   }
  // }, [toggleDarkMode])

  return (
    <arc-container fullscreen>
      <AppBar />
      <Routes>
        <Route path="/" element={<Projects />}></Route>
        <Route path="/project/:projectId" element={<Apps />} />
      </Routes>
    </arc-container>
  )
}

export default Frame
