import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import FitScreenIcon from '@mui/icons-material/FitScreen'
import IconButton from '@material-ui/core/IconButton'

interface IProps {
  api: any
  triggerLoading: any
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      paddingTop: '10px',
      paddingLeft: '10px',
      position: 'absolute',
      zIndex: 1
    }
  })

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const Controls: React.FC<PropsType> = (props) => {
  const { classes } = props
  function fitToScreen(api) {
    api.scene.camera.zoomAsync()
  }

  // // Fit to screen on double click on mouse wheel click
  // var count = 0
  // var timeout
  // var myButton: any = document.getElementById('sdv-container')
  // myButton.addEventListener('auxclick', captureMouseWheel, false)

  // function captureMouseWheel(e) {
  //   if (e.button === 1) {
  //     count++
  //     if (!timeout) {
  //       timeout = setTimeout(function () {
  //         timeout = undefined
  //         if (count >= 2) {
  //           fitToScreen(props.api)
  //           count = 0
  //         }
  //       }, 250)
  //     }
  //   } else {
  //     count = 0
  //   }
  // }

  return (
    <div className={classes.root}>
      <IconButton aria-label="fitToScreen" onClick={() => fitToScreen(props.api)}>
        <FitScreenIcon style={{ fontSize: 30 }} />
      </IconButton>
    </div>
  )
}

export default withStyles(styles)(Controls)
