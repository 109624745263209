import { EnvironmentConfig } from '../shared/interfaces'
import localConfig from './local'
import devConfig from './dev'
import prodConfig from './prod'

let config: EnvironmentConfig
const hostname = window.location.hostname
if (hostname === 'localhost') {
  config = localConfig
} else if (hostname === 'bridges.arup.com') {
  config = prodConfig
} else {
  config = devConfig
}

export default config
