import { EnvironmentConfig } from '../shared/interfaces'

export default {
  project: {
    name: 'local',
    api: {
      url: 'https://api.dev.bridges.arup.com/'
    }
  },
  sensor: {
    name: 'local',
    api: {
      url: 'https://k3p7spgxrc.execute-api.eu-west-1.amazonaws.com/Prod/',
      key: 'Jk1jC7LGQt3uOqRLlp11U63usuV7g3HDafgpBARA'
    }
  },
  activeDirectory: {
    auth: {
      clientId: 'd852f89f-93b3-4f4b-84e2-ee7d69106c7f', // Bridge Portal Dev
      authority: 'https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea',
      redirectUri: 'http://localhost:8080/'
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  }
} as EnvironmentConfig
